<novo-loading *ngIf="loading"></novo-loading>
<app-structured-seo *ngIf="!loading" [jobData]="job"></app-structured-seo>
<div class="background" *ngIf="!loading">
    <div class="job-container" [ngClass]="{'safari-scroll': isSafariAgent}">
        <button theme="dialogue" (click)="goToJobList()" icon="previous" side="left" data-automation-id="back-button">{{ 'BACK'   | translate }}</button>
        <div class="job-header">
            <span class="job-title">{{ job.title }}</span>
            <br/>
            <span class="category" *ngIf="job.publishedCategory && showCategory">
                {{ job.publishedCategory?.name }}
            </span>
            <div class="job-info-container">
                <app-info-chips [job]="job"></app-info-chips>
            </div>
        </div>
        <div class="job-description-text" [innerHTML]="job.publicDescription"></div>
        <div class="share">
            <button theme="primary" *ngIf="!alreadyApplied" (click)="apply()" data-automation-id="apply-button">{{ 'APPLY_NOW'   | translate }}</button>
            <button theme="primary" *ngIf="alreadyApplied" disabled="true" data-automation-id="applied-button">{{ 'ALREADY_APPLIED'   | translate }}</button>
            <button theme="icon" icon="email" (click)="emailLink()" *ngIf="showShareButtons"></button>
            <button theme="icon" icon="twitter" (click)="shareTwitter()" *ngIf="showShareButtons"></button>
            <button theme="icon" icon="facebook" (click)="shareFacebook()" *ngIf="showShareButtons"></button>
            <button theme="icon" icon="linkedin" (click)="shareLinkedin()" *ngIf="showShareButtons"></button>
            <button theme="dialogue" icon="share" (click)="toggleShareButtons()" *ngIf="!showShareButtons">{{ 'SHARE_POST'   | translate }}</button>
            <button theme="icon" icon="print" (click)="print()"></button>
        </div>
    </div>
</div>
