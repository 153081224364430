<div class="filter-header">
  <span>{{ 'FILTER_BY'  | translate }} {{ title }}</span>
</div>
<novo-loading *ngIf="loading"></novo-loading>

<novo-form [form]="form" *ngIf="!loading">
  <div class="novo-form-row">
    <novo-control [form]="form" [control]="control"></novo-control>
  </div>
</novo-form>
